import { financeInvoiceService } from '@/api';

import { DialogMixin } from '@/mixins/dialog';
import { FinaneInvoiceList, InstallAttachmentsList, InvoiceEditForm } from '@/resource/model';
import { messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
// import { ElButton } from 'element-ui/types/button';
import { ElForm } from 'element-ui/types/form';

import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { OsFileUpload } from '@/components';

import UploadAttachment from '@/views/finance-management/components/upload-attachment/upload-attachment.vue';

@Component({
  components: {
    OsFileUpload,
    UploadAttachment
  },
  i18n: {
    messages: {
      zh: {
        upload: {
          importExceed: '上传文件数量超出',
          dragUpload: '将文件拖到此处，或',
          clickUpload: '点击上传',
          acceptExtensionTip: '支持',
          maxUploadSizeTip: '单个文件不超过',
          loadingText: '文件上传中，请等待...',
          limit: '最多上传',
          files: '个文件'
        }
      },
      en: {
        upload: {
          importExceed: 'The number of uploaded files exceeds the threshold',
          dragUpload: 'Drag the file here, or',
          clickUpload: ' click on the upload',
          maxUploadSizeTip: 'The maximum file size is ',
          acceptExtensionTip: 'Supported extension:',
          loadingText: 'Uploading...',
          limit: 'Upload up to',
          files: 'files'
        }
      }
    }
  }
})
export default class EditInvoice extends mixins(DialogMixin) {
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return null;
    }
  })
  public readonly invoice!: FinaneInvoiceList;

  public form: InvoiceEditForm = {
    billId: 0,
    id: 0,
    invoiceNo: null,
    invoicingTime: null,
    invoiceFileIdList: []
  };

  public fileList: Array<InstallAttachmentsList> = [];

  public loading: boolean = false;

  public formRules: { [P in keyof Partial<InvoiceEditForm>]: Array<Object> } = {
    invoiceNo: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('financeInvoice.inputInvoiceNo')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    invoicingTime: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('financeInvoice.selectInvoicingTime')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public readonly importPath = `platform/upload/installAttachment/`;

  // public fileImportOptions: OsFileUploadOptions = {
  //   autoUpload: true,
  //   multiple: false,
  //   limit: 1,
  //   drag: false,
  //   hiddenUploadTip: false,
  //   showFileList: true,
  //   uploadLoading: false,
  //   action: '',
  //   accept: '.jpg,.png,.jpeg,.pdf,.ofd,.zip',
  //   maxSize: 5,
  //   fileList: [],
  //   onRemove: this.removeFile,
  //   httpRequest: this.customRequest,
  //   onExceed: this.onExceed,
  //   beforeUpload: this.beforeUpload,
  //   loadingText: translation('dialog.importLoadingText')
  // };
  // public uploadBtnOptions: { name?: string } & Partial<ElButton> = {
  //   plain: true
  // };
  // public get maxUploadSize(): string {
  //   if (this.fileImportOptions.maxSize && this.fileImportOptions.maxSize < 1) {
  //     return `${parseFloat((this.fileImportOptions.maxSize * 1024).toFixed(2))}KB`;
  //   }
  //   if (this.fileImportOptions.maxSize && this.fileImportOptions.maxSize > 1024) {
  //     return `${parseFloat((this.fileImportOptions.maxSize / 1024).toFixed(2))}GB `;
  //   }
  //   return `${this.fileImportOptions.maxSize}MB`;
  // }

  // private assemblyMinIOFileMissions: Array<Promise<void>> = [];
  // private toUploadFiles: Array<MinIOFile> = [];

  /**
   * 由于beforeUpload钩子返回false，会触发onRemove，
   * 而为了限制同名文件上传，beforeUpload可能会返回false，导致上传同名文件时，会删除掉之前已经选择过的文件
   * 所以只能自己加一个属性，控制是否删除文件
   */
  private isRepeatFileName = false;
  public dialogOpen(): void {
    this.$nextTick(() => {
      // (this.$refs.upload as Upload).clearFiles();
      // this.fileImportOptions.fileList = [];
      // this.assemblyMinIOFileMissions = [];
      this.fileList = [];
      const { billId, id, invoiceNo, invoicingTime, invoiceFileList } = this.invoice;

      this.form = {
        billId,
        id,
        invoiceNo: invoiceNo,
        invoicingTime: invoicingTime,
        invoiceFileIdList: []
      };
      if (invoiceFileList!.length > 0) {
        this.fileList = invoiceFileList!;
      }
    });
  }

  public dialogClosed(): void {
    console.log('关闭');
    // (this.$refs.upload as Upload).clearFiles();
    (this.$refs.editForm as Form).resetFields();
    // this.fileImportOptions.fileList = [];
    this.form = {
      billId: 0,
      id: 0,
      invoiceNo: '',
      invoicingTime: '',
      invoiceFileIdList: []
    };
  }

  public syncFileId(data: Array<InstallAttachmentsList>): void {
    this.form.invoiceFileIdList = data.map(x => x.id);
  }

  // public async customRequest(requestOptions: HttpRequestOptions): Promise<void> {
  //   this.assemblyMinIOFileMissions.push(this.assemblyMinIOFile(requestOptions));
  //   this.confirmUpload();
  // }
  // private async assemblyMinIOFile(requestOptions: HttpRequestOptions): Promise<void> {
  //   try {
  //     const minioFile: MinIOFile = {
  //       name: requestOptions.file.name,
  //       stream: Buffer.from(await requestOptions.file.arrayBuffer()),
  //       metadata: { 'Content-Type': requestOptions.file.type },
  //       size: requestOptions.file.size,
  //       originFile: requestOptions.file
  //     };

  //     if (!this.toUploadFiles.map(x => x.name).includes(minioFile.name)) {
  //       this.toUploadFiles.push(minioFile);
  //     }
  //     return Promise.resolve();
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // }

  // /**
  //  * 删除已选择的文件
  //  * @param file 要删除的文件
  //  * @returns
  //  */
  // private removeFile(file: ElUploadInternalFileDetail, fileList: Array<any>): void {
  //   this.form.fileId = null;
  //   (this.$refs.upload as Upload).clearFiles();
  //   this.fileImportOptions.fileList = fileList;
  //   if (!this.isRepeatFileName) {
  //     this.toUploadFiles = this.toUploadFiles.filter(x => x.name !== file.name);
  //     return;
  //   }
  //   this.isRepeatFileName = false;
  // }
  /**
   * 文件上传前的钩子
   * @param file 文件
   * @returns
   */
  // private beforeUpload(file: ElUploadInternalRawFile): boolean {
  //   const fileNames = this.fileImportOptions.fileList?.map(x => getFileName(x.name)) || [];
  //   const isExist = fileNames.includes(getFileName(file.name));
  //   this.isRepeatFileName = isExist;
  //   return !isExist;
  // }
  // private onExceed(): void {
  //   Message.warning(this.$t('upload.importExceed') as string);
  // }

  private async onSubmit(): Promise<void> {
    try {
      this.setLoading(true);
      await (this.$refs.editForm as ElForm).validate();
      await financeInvoiceService.editInvoiceInfo(this.form);
      this.$emit('edit-success', cloneDeep(this.form));
      Message.success(translation('operationRes.operationSuccess'));
      this.closeDialog();
    } catch (error) {
      if (error) {
        messageError(error);
      }
    } finally {
      this.setLoading(false);
    }
  }
  // @debounce()
  // private async confirmUpload(): Promise<void> {
  //   this.setLoading(true);
  //   // this.setUploadLoading(true);
  //   try {
  //     await Promise.all(this.assemblyMinIOFileMissions);
  //     if (this.toUploadFiles.length === 0) {
  //       return;
  //     }
  //     const uploadedFiles = await FileService.batchUpload(
  //       this.toUploadFiles,
  //       `${this.importPath}${UserModule.account}`
  //     );
  //     this.form.fileId = uploadedFiles.reverse().map(x => x.id!)[0];
  //     Message.success(translation('operationRes.uploadSucess'));
  //   } catch (error) {
  //     messageError(error);
  //   } finally {
  //     this.setLoading(false);
  //   }
  // }
}
