import { billingService, financeInvoiceService, invoiceService, taxRateService, workflowDesignService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { ChargeUnitEnum, InvoiceColorEnum, InvoiceModeEnum, InvoiceTypeEnum } from '@/resource/enum';
import {
  FinanceInvoiceInfo,
  InvoiceResource,
  TaxRateResource,
  SaveApplyChangeInvoice,
  FinanceInvoiceDetail,
  WorkflowDesignResource,
  InstallAttachmentsList
} from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
// import { UserModule } from '@/store/modules/user';
// import { dateFormat, messageError, translation } from '@/utils';
import { ElForm } from 'element-ui/types/form';

import { mixins } from 'vue-class-component';
import { Component, Prop, ProvideReactive, Vue } from 'vue-property-decorator';
import SelectInvoice from '../../billing/add-billing/invoice-info/add-invoice/select-invoice/select-invoice.vue';
import SelectCustomerInvoice from '../../billing/add-billing/invoice-info/add-invoice/select-customer-invoice/select-customer-invoice.vue';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { Notification } from 'element-ui';
import { InitiateApproval } from '@/views/dialogs';
import UploadAttachment from '@/views/finance-management/components/upload-attachment/upload-attachment.vue';
import { messageError, translation } from '@/utils';
@Component({
  components: { SelectInvoice, SelectCustomerInvoice, InitiateApproval, UploadAttachment }
})
export default class ApplyChangeInvoice extends mixins(DialogMixin) {
  @Prop({
    type: Number,
    required: true
  })
  public id!: number;

  public loading: boolean = false;

  public invoiceForm: Partial<SaveApplyChangeInvoice> = {
    color: InvoiceColorEnum.blue,
    invoiceMethod: undefined,
    fileList: [],
    fileIdList: [],
    remark: '',
    companyName: '',
    taxNumber: '',
    bankName: '',
    bankNumber: '',
    tel: '',
    address: '',
    invoiceType: undefined,
    account: undefined,
    invoiceCompany: '',
    billInvoiceDetailRequestList: []
  };

  public customerName = '';

  public formRules: { [P in keyof Partial<FinanceInvoiceInfo>]: Array<object> } = {
    color: [
      {
        required: true,
        validator: (rule: any, value: InvoiceColorEnum, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectCustomer')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    invoiceMethod: [
      {
        required: true,
        validator: (rule: any, value: InvoiceModeEnum, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectSettleCurrency')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    companyName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectInvoiceTitle')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    taxNumber: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.inputTaxNumber')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    invoiceType: [
      {
        required: true,
        validator: (rule: any, value: InvoiceTypeEnum, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectInvoiceType')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    account: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.inputPaymentDay')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    invoiceCompany: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('billing.selectInvoiceCompany')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  // public detailUploadOptions: OsFileUploadOptions = {
  //   accept: '.pdf,.xlsx,.xls,.png,.jpg,.jpeg',
  //   autoUpload: true,
  //   limit: 1,
  //   multiple: false,
  //   drag: false,
  //   action: '',
  //   uploadLoading: false,
  //   fileList: [],
  //   onRemove: this.onRemove,
  //   httpRequest: this.uploadAttachment
  // };

  public colors: NormalSelectOptions = billingService.getInvoiceColors();

  public invoiceModes: NormalSelectOptions = billingService.getInvoiceModes();

  public invoiceTypes: NormalSelectOptions = billingService.getInvoiceTypes();

  public selectInvoiceDialog = false;

  public selectCustomerInvoiceDialog = false;

  /**
   * 审批
   */
  public businessId: number = 0;
  public approvalDialogVisible: boolean = false;
  public workflowDesign: WorkflowDesignResource = {} as WorkflowDesignResource;

  public tableOptions: OsTableOption<Partial<FinanceInvoiceDetail>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    showSummary: true,
    sumPropsOptions: [
      {
        prop: 'count'
      },
      {
        prop: 'amountExcludingTax',
        fixPlace: 2
      },
      {
        prop: 'amountIncludingTax',
        fixPlace: 2
      },
      {
        prop: 'taxAmount',
        fixPlace: 2
      }
    ]
  };

  public columnOptions: Array<OsTableColumn<FinanceInvoiceDetail>> = [
    {
      type: 'index',
      prop: 'id',
      label: 'common.index',
      width: '80px'
    },
    {
      prop: 'taxRevenueName',
      label: 'billing.taxRevenueName',
      minWidth: '180px'
    },
    {
      prop: 'serviceName',
      label: 'billing.serviceName',
      minWidth: '180px'
    },
    {
      prop: 'priceUnit',
      label: 'billing.priceUnit',
      minWidth: '180px',
      formatter: (rowData: Object): string => {
        const key = ChargeUnitEnum[(rowData as FinanceInvoiceDetail).priceUnit]
          ? `chargeUnit.${ChargeUnitEnum[(rowData as FinanceInvoiceDetail).priceUnit]}`
          : '';
        return key ? translation(key) : '--';
      }
    },
    {
      prop: 'count',
      label: 'billing.priceCount',
      minWidth: '180px',
      formatter: (rowData: Object): string => {
        return (rowData as FinanceInvoiceDetail).count.toFixed(2);
      }
    },
    {
      prop: 'priceBeforeTax',
      label: 'billing.priceBeforeTax',
      minWidth: '180px',
      formatter: (rowData: Object): string => {
        return (rowData as FinanceInvoiceDetail).priceBeforeTax.toFixed(6);
      }
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.amountExcludingTax',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return (rowData as FinanceInvoiceDetail).amountExcludingTax.toFixed(2);
      }
    },
    {
      prop: 'taxRate',
      label: 'billing.taxRate',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return (rowData as FinanceInvoiceDetail).taxRate.toFixed(2);
      }
    },
    {
      prop: 'taxAmount',
      label: 'billing.taxAmount',
      minWidth: '160px',
      formatter: (rowData: Object): string => {
        return (rowData as FinanceInvoiceDetail).taxAmount.toFixed(2);
      }
    },
    {
      prop: 'amountIncludingTax',
      label: 'billing.amountIncludingTax',
      minWidth: '180px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        return (rowData as FinanceInvoiceDetail).amountIncludingTax.toFixed(2);
      }
    }
  ];

  public taxRates: Array<TaxRateResource> = [];

  public platformInvoices: Array<InvoiceResource> = [];
  private readonly canEditCells: Array<Partial<keyof FinanceInvoiceDetail>> = ['serviceName'];

  @ProvideReactive()
  private customerId = 0;

  public created(): void {
    this.getplaftormInvoice();
    if (!this.id) {
      return;
    }
    financeInvoiceService
      .getById(this.id)
      .then(res => {
        if (res.fileList) {
          this.invoiceForm.fileIdList = res.fileList.map(x => x.id) || [];
        }

        Object.assign(this.invoiceForm, res);
        // 指定客户相关数据
        this.customerId = res.customerId;
        this.customerName = res.customerName;

        // 指定发票明细
        this.tableOptions.data = this.invoiceForm.billInvoiceDetailRequestList || [];
        this.tableOptions.data.forEach(x => {
          this.initInputDynamicProp(x);
        });
        // 回显发票附件
        // if (this.invoiceForm.file) {
        //   const fileName = getMinioFileName(this.invoiceForm.file);
        //   this.detailUploadOptions.fileList = [{ name: fileName, url: '' }];
        // }

        // 指定来源
        this.invoiceForm.originalCode = res.invoiceCode;
        this.invoiceForm.originalId = res.id;
      })
      .catch(error => {
        messageError(error);
      });
  }

  public onSubmit(): Promise<void> {
    return new Promise((resolve, reject) => {
      financeInvoiceService
        .applyChangeInvoice(this.invoiceForm as SaveApplyChangeInvoice)
        .then(id => {
          this.businessId = id;
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public async submitApply(id: number): Promise<void> {
    (this.$refs.invoiceForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }

      let errorMsg = '';
      if (this.tableOptions.data.length === 0) {
        errorMsg += `<br />未填写发票明细！<br />`;
      }
      this.tableOptions.data.forEach((x, index) => {
        if (!x.serviceName) {
          errorMsg += `<br />序号为${index + 1}的 “服务名称” 不能为空！<br />`;
        }
        // if (!x.count) {
        //   errorMsg += `序号为${index + 1}的 “计价数量” 不能为空！<br />`;
        // }
        // if (!x.priceBeforeTax) {
        //   errorMsg += `序号为${index + 1}的 “未税单价” 不能为空！<br />`;
        // }
        // if (!x.amountExcludingTax) {
        //   errorMsg += `序号为${index + 1}的 “未税金额” 不能为空！<br />`;
        // }
        // if (x.taxRate !== 0 && !x.taxRate) {
        //   errorMsg += `序号为${index + 1}的 “税率” 不能为空！<br />`;
        // }
        // if (!x.amountIncludingTax) {
        //   errorMsg += `序号为${index + 1}的 “含税金额” 不能为空！<br />`;
        // }
      });

      if (errorMsg) {
        Notification.error({
          title: translation('dialog.saveFailed'),
          duration: 0,
          dangerouslyUseHTMLString: true,
          message: `<span>${errorMsg}</span><br />`
        });
        return;
      }
      this.workflowDesign = (await workflowDesignService.getByTag('billInvoiceHandler')) ?? {};
      if (this.workflowDesign.id) {
        this.businessId = id;
        this.approvalDialogVisible = true;
      } else {
        this.$message.warning('当前业务没有设置审批任务');
        this.success();
      }
    });
  }
  public success(): void {
    this.$emit('apply-success');
    this.closeDialog();
  }
  private get fileList(): Array<InstallAttachmentsList> {
    return this.invoiceForm.fileList!;
  }
  public syncFileId(data: Array<InstallAttachmentsList>): void {
    this.invoiceForm.fileIdList = data.map(x => x.id);
  }

  public openSelectInvoiceDialog(): void {
    this.selectInvoiceDialog = true;
  }

  public openSelectCustomerInvoiceDialog(): void {
    this.selectCustomerInvoiceDialog = true;
  }

  public handleSelectedInvoice(invoice: InvoiceResource): void {
    this.invoiceForm.invoiceCompany = invoice.companyName;
    this.getTaxRates(invoice.id);
  }

  public handleSelectedCustomerInvoice(customerInvoice: InvoiceResource): void {
    this.invoiceForm.bankName = customerInvoice.bankName;
    this.invoiceForm.invoiceType = customerInvoice.invoiceType;
    this.invoiceForm.taxNumber = customerInvoice.taxCode;
    this.invoiceForm.address = customerInvoice.address;
    this.invoiceForm.tel = customerInvoice.contactNumber;
    this.invoiceForm.bankNumber = customerInvoice.bankAccount;
    this.invoiceForm.companyName = customerInvoice.companyName;
  }
  /**
   * 处理可编辑单元格的点击事件
   * @param prop 处理
   * @param rowData 当前行的数据
   */
  public handlePropClick(prop: string, rowData: { [P: string]: any }): void {
    rowData[`${prop}Input`] = true;
  }
  /**
   * 初始化项次内，部分可以动态使用input编辑的属性
   * @param rowObj行对象
   * @param canEditCells 要初始化的列
   */
  private initInputDynamicProp(rowObj: any): void {
    this.canEditCells.forEach(x => {
      Vue.set(rowObj, `${x}Input`, false);
    });
  }

  /**
   * 上传附件
   * @param requestOptions
   */
  // private async uploadAttachment(requestOptions: HttpRequestOptions): Promise<void> {
  //   try {
  //     this.detailUploadOptions.uploadLoading = true;
  //     this.loading = true;
  //     const minioFile: MinIOFile = {
  //       name: `${dateFormat(new Date(), 'yyyyMMDDHHmmss')}_${requestOptions.file.name}`,
  //       stream: Buffer.from(await requestOptions.file.arrayBuffer())
  //     };
  //     await minioService.init();
  //     const filePath = `platform/upload/${UserModule.account}/invoice`;
  //     await minioService.uploadByStream(minioFile, filePath);
  //     const path = `${filePath}/${minioFile.name}`;
  //     this.invoiceForm.file = path;
  //     this.detailUploadOptions.uploadLoading = false;
  //     this.loading = false;
  //     Message.success(translation('operationRes.uploadSucess'));
  //   } catch (error) {
  //     Message.success(translation('operationRes.uploadFailed'));
  //     this.detailUploadOptions.uploadLoading = false;
  //     this.loading = false;
  //     (this.$refs['detailUpload'] as OsFileUpload).clearFiles();
  //   }
  // }

  // private onRemove(): void {
  //   this.invoiceForm.file = '';
  // }

  /**
   * 获取税收分类
   * @param invoiceId 平台端开票id
   */
  private getTaxRates(invoiceId: number): void {
    taxRateService
      .getAllTaxRates(invoiceId)
      .then(res => {
        this.taxRates = res;
        // 重新获取税收分类后，只清空已选的税收分类，但不用清除相关联的税率
        // this.tableOptions.data.forEach(x => {
        //   x.taxRevenueName = '';
        // });
      })
      .catch(error => {
        messageError(error);
      });
  }

  private getplaftormInvoice(): void {
    invoiceService
      .getAllInvoices()
      .then(res => {
        this.platformInvoices.push(...res);
        // 还需要根据开票公司，拿到开票公司对应的税收分类
        const findInvice = this.platformInvoices.find(x => x.companyName === this.invoiceForm.invoiceCompany);
        if (findInvice) {
          this.getTaxRates(findInvice.id);
        }
      })
      .catch(error => {
        messageError(error);
      });
  }
}
