import { billingService, customerService, FileService, financeInvoiceService, todoTaskService } from '@/api';
import { SortOptions } from '@/api/base';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { PagingMixin } from '@/mixins/paging';
import { FinanceInvoiceStatusEnum, InvoiceColorEnum, InvoiceTypeEnum, PageQueryPlanEnum } from '@/resource/enum';
import { CustomerResource, FinanceInvoiceListQuery, FinaneInvoiceList } from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import {
  dateFormat,
  debounce,
  downloadFileByBlob,
  messageError,
  messageErrors,
  showWarningConfirm,
  translation
} from '@/utils';
import { Message } from 'element-ui';
import moment from 'moment';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import ApplyChangeInvoice from './apply-change-invoice/apply-change-invoice.vue';
import TakeInvoice from './take-invoice/take-invoice.vue';
import EditInvoice from './edit-invoice/edit-invoice.vue';
import { QueryPlanOperationOption } from '@/components/os-query-plan/os-query-plan';
import { OsQueryPlan } from '@/components';
@Component({
  components: { ApplyChangeInvoice, TakeInvoice, EditInvoice }
})
export default class FinanceInvoice extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<FinaneInvoiceList> = {
    loading: false,
    data: [],
    fit: true,
    defaultSort: { order: 'ascending', prop: 'billCode' }
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<FinaneInvoiceList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'invoiceCode',
      label: 'billing.code',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'color',
      label: 'billing.color',
      showOverflowTooltip: true,
      minWidth: '100px',
      formatter: (rowData: Object): string => {
        if (!(rowData as FinaneInvoiceList).color) {
          return '';
        }
        return translation(`invoiceColor.${InvoiceColorEnum[(rowData as FinaneInvoiceList).color]}`);
      }
    },
    {
      prop: 'billCode',
      label: 'financeInvoice.billCode',
      showOverflowTooltip: true,
      minWidth: '180px',
      sortable: 'custom'
    },
    {
      prop: 'customerName',
      label: 'billing.customerName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'invoiceNo',
      label: 'billing.invoiceNumber',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'fileId',
      label: 'billing.invoiceFile',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (rowData: Object): string => {
        let str = '';
        if (
          (rowData as FinaneInvoiceList).invoiceFileList &&
          (rowData as FinaneInvoiceList).invoiceFileList!.length > 0
        ) {
          str = translation(`billing.uploaded`);
        } else {
          str = translation(`billing.notUploaded`);
        }
        return str;
      }
    },
    {
      prop: 'companyName',
      label: 'billing.invoiceTitle',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'currencyName',
      label: 'billing.settleCurrency',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'amountExcludingTax',
      label: 'billing.noTaxAmount',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        return (rowData as FinaneInvoiceList).amountExcludingTax.toFixed(2);
      }
    },
    {
      prop: 'taxAmount',
      label: 'billing.taxAmount',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        return (
          (rowData as FinaneInvoiceList).amountIncludingTax - (rowData as FinaneInvoiceList).amountExcludingTax
        ).toFixed(2);
      }
    },
    {
      prop: 'amountIncludingTax',
      label: 'billing.amountIncludingTax',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        return (rowData as FinaneInvoiceList).amountIncludingTax.toFixed(2);
      }
    },
    {
      prop: 'invoiceType',
      label: 'billing.invoiceType',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        if (!(rowData as FinaneInvoiceList).invoiceType) {
          return '--';
        }
        return translation(`invoiceType.${InvoiceTypeEnum[(rowData as FinaneInvoiceList).invoiceType]}`);
      }
    },
    {
      prop: 'account',
      label: 'billing.paymentDay',
      showOverflowTooltip: true,
      minWidth: '120px'
    },
    {
      prop: 'status',
      label: 'billing.status',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'expiryDate',
      label: 'financeInvoice.expiryDate',
      showOverflowTooltip: true,
      minWidth: '150px',
      formatter: (rowData: Object): string => {
        if (!(rowData as FinaneInvoiceList).invoicingTime) {
          return '--';
        }

        return moment((rowData as FinaneInvoiceList).invoicingTime)
          .add((rowData as FinaneInvoiceList).account, 'days')
          .format('YYYY-MM-DD');
      }
    },
    {
      prop: 'createUserName',
      label: 'common.createUser',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: object): string => {
        return dateFormat((row as FinaneInvoiceList).createTime);
      }
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<FinaneInvoiceList> = {
    fixed: 'right',
    width: '100px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'finance:invoice:editInvoicing',
        dynamicHidden: (rowData: FinaneInvoiceList): boolean => {
          return ![FinanceInvoiceStatusEnum.hasMakeInvoice, FinanceInvoiceStatusEnum.waitMakeInvoice].includes(
            rowData.status
          );
        },
        handleClick: this.openEditDialog
      }
    ]
  };

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Input',
      field: 'billCode',
      label: 'financeInvoice.billCode',
      option: {
        placeholder: 'financeInvoice.inputBillCode'
      }
    },
    {
      type: 'Select',
      field: 'customerId',
      label: 'billing.customer',
      option: {
        placeholder: 'billing.selectCustomer',
        filterable: true
      },
      optionData: []
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: this.getStatus
    },
    {
      type: 'Select',
      field: 'invoiceType',
      label: 'billing.invoiceType',
      option: {
        placeholder: 'billing.selectInvoiceType'
      },
      optionData: this.getInvoiceTypes
    },
    {
      type: 'Select',
      field: 'color',
      label: 'billing.color',
      option: {
        placeholder: 'billing.selectColor'
      },
      optionData: this.getColors
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'billing.applyTime',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    },
    {
      type: 'DateRangePicker',
      field: 'invoiceTime',
      label: 'billing.invoicingTime',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    // {
    //   type: 'primary',
    //   slot: 'start',
    //   label: 'financeInvoice.makeInvoice',
    //   operationType: 'makeInvoice',
    //   icon: 'el-icon-check',
    //   disabled: true,
    //   permissionCode: 'finance:invoice:invoicing',
    //   handleClick: this.openTakeInvoiceDialog
    // },
    {
      type: 'danger',
      slot: 'start',
      label: 'financeInvoice.applyChange',
      operationType: 'applyChange',
      disabled: true,
      plain: true,
      icon: 'el-icon-refresh',
      permissionCode: 'finance:invoice:exchangeInvoice',
      handleClick: this.applyChangeInvoice
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.cancel',
      operationType: 'cancel',
      disabled: true,
      plain: true,
      icon: 'el-icon-caret-left',
      permissionCode: 'finance:invoice:withdrawn',
      handleClick: this.cancelApply
    },
    {
      slot: 'start',
      label: 'financeInvoice.exportInvoiceWithDetail',
      operationType: 'export',
      icon: 'el-icon-download',
      permissionCode: 'finance:invoice:exportData',
      plain: true,
      handleClick: this.exportWithDetail
    },
    {
      slot: 'start',
      label: 'financeInvoice.exportInvoice',
      operationType: 'export',
      icon: 'el-icon-download',
      permissionCode: 'finance:invoice:exportData',
      plain: true,
      handleClick: this.export
    },
    {
      slot: 'start',
      label: 'billing.downloadInvoice',
      operationType: 'download',
      icon: 'el-icon-download',
      permissionCode: 'finance:invoice:downloadInvoice',
      disabled: true,
      plain: true,
      handleClick: this.download
    }
  ];

  /**
   * 页面标识
   */
  public code: number = PageQueryPlanEnum.financeInvoice;
  /**
   * 查询方案编辑按钮
   */
  public queryPlanEndOption: Array<QueryPlanOperationOption> = [
    {
      id: 0,
      type: 'primary',
      slot: 'end',
      label: 'button.save',
      permissionCode: 'system:query:template:save',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).dialogOpened();
      }
    },
    {
      id: 1,
      type: 'text',
      slot: 'end',
      label: 'button.edit',
      permissionCode: 'system:query:template:delete',
      handleClick: (): void => {
        (this.$refs.OsQueryPlan as OsQueryPlan).editDialogOpened();
      }
    }
  ];

  public customers: Array<CustomerResource> = [];

  public selectedRows: Array<FinaneInvoiceList> = [];

  public applyChangeInvoiceVisible = false;

  public takeInvoiceVisible = false;

  public takeInvoiceData: Array<FinaneInvoiceList> = [];

  public invoiceId: number | undefined = undefined;

  public editVisible = false;

  public editPending: FinaneInvoiceList | null = null;

  private queryForm: Partial<FinanceInvoiceListQuery> & {
    createTime: Array<string>;
    invoiceTime: Array<string>;
  } = {
    keywords: '',
    status: undefined,
    customerId: undefined,
    createTime: [],
    invoiceTime: [],
    invoiceType: undefined,
    color: undefined
  };

  private defaultQueryForm: Partial<
    FinanceInvoiceListQuery & { billCode: string; createTime: Array<string>; invoiceTime: Array<string> }
  > = {
    billCode: '',
    createTime: [],
    customerId: undefined,
    invoiceTime: [],
    invoiceType: undefined,
    keywords: '',
    status: undefined
  };

  private sortOptions: SortOptions<FinaneInvoiceList> = this.tableOption.defaultSort!;

  public activated(): void {
    this.queryClick();
  }
  public mounted(): void {
    this.queryClick();
  }
  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'finance-invoice');
    this.getCustomers();
  }

  public reactivate(): void {
    if (this.$route.meta!.needReload) {
      this.reloadData();
      this.$route.meta!.needReload = false;
    }
  }

  public reloadData(): void {
    this.clearSelection();
    // 重置查询方案
    (this.$refs.OsQueryPlan as OsQueryPlan).id = 0;
    this.loadData();
  }

  public queryData(): void {
    this.paging.currentPage = 1;
    this.reloadData();
  }

  @debounce()
  public queryClick(): void {
    if (this.$route.query.queryPlanName) {
      this.paging.currentPage = 1;
      (this.$refs.OsQueryPlan as OsQueryPlan).selectQueryPlan(this.$route.query.queryPlanName as string);
      return;
    }
    this.loadData();
  }

  /**
   * 执行查询方案
   * @param json
   */
  public queryList(json: string): void {
    this.paging.currentPage = 1;
    Object.assign(this.queryForm, this.defaultQueryForm);
    Object.assign(this.queryForm, JSON.parse(json));
    this.loadData();
  }

  public linkToDetails(invoice: FinaneInvoiceList): void {
    this.$router.push({
      path: 'invoice-details',
      query: {
        id: invoice.id.toString()
      }
    });
  }

  public handleSelectionChange(selectedData: Array<FinaneInvoiceList>): void {
    this.selectedRows = selectedData;
  }

  public handleSortChange(sortOptions: SortOptions<FinaneInvoiceList>): void {
    this.sortOptions = sortOptions;
    this.paging.currentPage = 1;
    this.reloadData();
  }

  /**
   * 处理申请换开成功
   */
  public handleApplied(): void {
    this.reloadData();
  }

  public getStatusClass(invoice: FinaneInvoiceList): string {
    switch (invoice.status) {
      case FinanceInvoiceStatusEnum.waitAudit:
        return 'warning-dot';
      case FinanceInvoiceStatusEnum.failedAudit:
        return 'danger-dot';
      case FinanceInvoiceStatusEnum.waitMakeInvoice:
        return 'primary-dot';
      case FinanceInvoiceStatusEnum.hasMakeInvoice:
        return 'success-dot';
      case FinanceInvoiceStatusEnum.abolished:
        return 'danger-dot';
      default:
        return 'danger-dot';
    }
  }

  public getStatusName(invoice: FinaneInvoiceList): string {
    return FinanceInvoiceStatusEnum[invoice.status]
      ? `financeInvoiceStatus.${FinanceInvoiceStatusEnum[invoice.status]}`
      : 'common.unKnownStatus';
  }

  public takeInvoiceSuccess(): void {
    this.takeInvoiceData = [];
    this.reloadData();
  }

  public handleEditSuccess(): void {
    this.editPending = null;
    this.clearSelection();
    this.reloadData();
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('queryForm.invoiceTime')
  private handleInvoiceTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.invoicingStartTime = undefined;
      this.queryForm.invoicingEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.invoicingStartTime = dateFormat(value[0]);
      this.queryForm.invoicingEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<FinaneInvoiceList>): void {
    const notControl = ['export'];
    this.operationOptions.forEach(x => {
      if (!notControl.includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  private clearSelection(): void {
    (this.$refs.financeInvoiceTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  private loadData(): void {
    this.tableOption.loading = true;
    financeInvoiceService
      .getList(this.queryForm, this.paging, this.sortOptions)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private applyChangeInvoice(): void {
    const ids: Array<number> = this.selectedRows
      .filter(x => x.status === FinanceInvoiceStatusEnum.hasMakeInvoice)
      .map(x => x.id);
    if (ids.length === 0) {
      Message.warning(translation('financeInvoice.changeNoStandard'));
      this.clearSelection();
      return;
    }
    this.applyChangeInvoiceVisible = true;
    this.invoiceId = this.selectedRows[0].id;
  }
  /**
   * 取消审核
   */
  private async cancelApply(): Promise<void> {
    try {
      if (this.selectedRows.length > 1) {
        Message.warning(translation('common.selectMaxDataTip'));
        return;
      }
      const allowStatus = [FinanceInvoiceStatusEnum.waitAudit];
      const ids: Array<number> = this.selectedRows.filter(x => allowStatus.includes(x.status)).map(x => x.id);

      if (ids.length === 0) {
        Message.warning(translation('billing.cancelApplyNoStandard'));
        this.clearSelection();
        return;
      }

      await showWarningConfirm(translation('billing.confirmCancelApply'));
      this.tableOption.loading = true;

      const params = {
        businessId: this.selectedRows[0].id,
        tag: 'billInvoiceHandler'
      };

      await todoTaskService.withdrawn(params);
      this.loadData();
      this.clearSelection();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      if (error === 'cancel') {
        Message.info(translation('operationRes.cancelOperation'));
        return;
      }
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  /**
   * 导出单据
   */
  private async export(): Promise<void> {
    this.tableOption.loading = true;
    try {
      this.queryForm.hasDetail = 2;
      const exportQuery = {
        idList: this.selectedRows.map(x => x.id),
        ...this.queryForm
      };
      const blob = await financeInvoiceService.export(exportQuery);
      downloadFileByBlob(`${translation('financeInvoice.exportFileName')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  /**
   * 导出单据(含明细)
   */
  private async exportWithDetail(): Promise<void> {
    this.tableOption.loading = true;
    try {
      this.queryForm.hasDetail = 1;
      const exportQuery = {
        idList: this.selectedRows.map(x => x.id),
        ...this.queryForm
      };
      const blob = await financeInvoiceService.export(exportQuery);
      downloadFileByBlob(`${translation('financeInvoice.exportFileName')}_${dateFormat(new Date())}.xlsx`, blob);
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOption.loading = false;
    }
  }

  private async download(): Promise<void> {
    const fileList: Array<number> = [];
    this.selectedRows.forEach(item => {
      if (item.invoiceFileList) {
        const ids = item.invoiceFileList.map(x => x.id);
        fileList.unshift(...ids);
      }
    });

    if (fileList.length <= 0) {
      // Message.warning(translation('installationOrder.selectNewReceiptsTip'));
      Message.warning(translation('没有可下载文件'));
      return;
    }
    try {
      FileService.downloadZip(fileList)
        .then(blob => {
          downloadFileByBlob(`${translation('billing.invoiceFile')}_${dateFormat(new Date())}.zip`, blob);
        })
        .catch(error => {
          messageError(error);
        })
        .finally(() => {});
    } catch (error) {
      messageErrors(error);
    }
  }

  private getCustomers(): void {
    customerService
      .getAllUsingCustomer()
      .then(res => {
        this.customers = res;
        const customerQuery = this.queryItemsOption.find(x => x.field === 'customerId');
        customerQuery!.optionData = res.map(x => {
          return { label: x.companyName, value: x.id };
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  private getStatus(): NormalSelectOptions {
    const status = financeInvoiceService.getStatus();
    status.forEach(x => {
      x.label = translation(x.label);
    });
    return status;
  }

  private getInvoiceTypes(): NormalSelectOptions {
    const invoiceTypes = billingService.getInvoiceTypes();
    invoiceTypes.forEach(x => {
      x.label = translation(x.label);
    });
    return invoiceTypes;
  }

  private getColors(): NormalSelectOptions {
    const colors = billingService.getInvoiceColors();
    colors.forEach(x => {
      x.label = translation(x.label);
    });
    return colors;
  }

  private openTakeInvoiceDialog(): void {
    const filterData = this.selectedRows.filter(x => x.status !== FinanceInvoiceStatusEnum.waitMakeInvoice);

    if (filterData.length > 0) {
      Message.warning(translation('financeInvoice.makeInvoiceNoStandard'));
      this.clearSelection();
      return;
    }

    this.takeInvoiceData = this.selectedRows.filter(x => x.status === FinanceInvoiceStatusEnum.waitMakeInvoice);
    this.takeInvoiceVisible = true;
  }

  private openEditDialog(item: FinaneInvoiceList): void {
    this.editPending = item;
    this.editVisible = true;
  }
}
