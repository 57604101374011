import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { DialogMixin } from '@/mixins/dialog';
import { dateFormat, limitPreviousForTimePicker, messageError, translation } from '@/utils';
import { ElForm } from 'element-ui/types/form';
import { FinaneInvoiceList } from '@/resource/model';
import { financeInvoiceService } from '@/api';
import { Message } from 'element-ui';
@Component({
  components: {}
})
export default class TakeInvoice extends mixins(DialogMixin) {
  @Prop({ type: Array, required: true })
  public invoices!: Array<FinaneInvoiceList>;

  public formRules: { [P in keyof { invoiceNos: string; invoicingTime: string }]: Array<object> } = {
    invoiceNos: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('financeInvoice.inputInvoiceNo')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    invoicingTime: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('financeInvoice.selectInvoicingTime')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public invoiceForm = {
    invoiceNos: '',
    invoicingTime: dateFormat(new Date(), 'YYYY-MM-DD')
  };

  public limitDateRangeOption = {
    disabledDate: limitPreviousForTimePicker
  };

  public dialogClosed(): void {
    (this.$refs.invoiceForm as ElForm).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.invoiceForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }

      try {
        this.setLoading(true);
        // 组装开票所需的数据格式
        const apiParams: Array<{ billId: number; id: number; invoiceNo: string; invoicingTime: string }> = [];
        const nos = this.invoiceForm.invoiceNos.split(',');
        if (nos.length !== this.invoices.length) {
          Message.warning(translation('financeInvoice.invoiceNumError'));
          return;
        }
        this.invoices.forEach((item, index) => {
          apiParams.push({
            billId: item.billId,
            id: item.id,
            invoiceNo: nos[index],
            invoicingTime: this.invoiceForm.invoicingTime
          });
        });

        await financeInvoiceService.makeInvoice(apiParams);
        this.$emit('take-invoice-success');
        this.closeDialog();
        Message.success(translation('operationRes.operationSuccess'));
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }
}
